<template>
    <section class="d-flex align-items-center justify-content-center">
        <img class="custom_anim"
            width="100" height="100" :src="logo"
            />
        <span class="ms-4">
            {{ label }}
        </span>
    </section>
</template>

<script setup>
    //images 
    const logo = require('ASSETS/wiks_logo')

    //props
    const props = defineProps({
        label: {
            type: String,
            required: false,
            default: 'Cargando información...'
        }
    })
</script>

<style lang="scss" scoped>

    .custom_anim {
        animation-name: pulsar;
        animation-duration: 7s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes pulsar {
        0%{
            transform: rotate(1deg) scale(1);
            opacity: 1;
        }

        50%{
            transform: rotate(180deg) scale(1.5);
            opacity: 0;
        }

        100% {
            transform: rotate(359deg) scale(1);
            opacity: 1;
        }
    }
</style>
