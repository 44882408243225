<template>
    <footer class="row p-0 m-0 bg-primary" style="min-height: 200px;">

        <div class="col-12 col-sm-6 col-lg-3 d-flex flex-center mt-3 mt-lg-2">
            <img class="zoom-hover" @click.stop="router.push({ path: '/'})" :src="wiks_logo" alt="logo wiks" width="261" height="199" loading="lazy"
                style="width: 180px; height: 150px; object-fit: scaledown; cursor: pointer;">
        </div>

        <div class="col-12 col-sm-6 my-3 my-lg-0 dmsans-semibold d-flex flex-row align-items-center justify-content-center">
            <ul class="list-unstyled text-sm-start text-center me-sm-5 me-3" v-if="has_session">
                <li v-for="(option, index ) in options" :key="`option-f-${index}`"  class="zoom-hover"> 
                    <a class="text-decoration-none text-white text-uppercase" @click.stop="router.push({ name: option.route})" style="cursor: pointer;">{{ option.label }}</a>
                </li>
            </ul>
            <ul class="list-unstyled text-sm-start text-center ms-sm-5 ms-3">
                <li v-for="(option, index ) in options2" :key="`option-f-${index}`" class="zoom-hover"> 
                    <a class="text-decoration-none text-white text-uppercase" @click.stop="router.push({ name: option.route})" style="cursor: pointer;">{{ option.label }}</a>
                </li>
            </ul>
        </div>

        <div class="col-12 col-lg-3 d-flex align-items-center justify-content-center flex-column pe-3">
            <div class="d-flex flex-row">
                <a href="https://play.google.com/store/apps/details?id=com.wiks.mx" target="_blank" rel="external">
                    <img :src="google_play" class="zoom-hover me-1 mb-3" alt="logo Playstore" width="120" height="40" loading="lazy">
                </a>
                <a href="https://apps.apple.com/gb/app/wiks/id1662030786" target="_blank">
                    <img :src="appstore" class="zoom-hover ms-1 mb-3" alt="logo AppStore" width="120" height="40" loading="lazy">
                </a>
            </div> 
            <div class="d-flex flex-row">
                <a href="#"
                    style="opacity: 1;">
                    <img :src="fb_logo" class="zoom-hover" alt="logo facebook" width="50" height="50" loading="lazy">
                </a>
                <a class="mx-2"
                    href="#" style="opacity: 1;">
                    <img :src="ins_logo" class="zoom-hover" alt="logo instagram" width="50" height="50" loading="lazy">
                </a>
                <a class="me-2" href="#" style="opacity: 1;">
                    <img :src="tk_logo" class="zoom-hover" alt="logo twitter" width="50" height="50" loading="lazy">
                </a>
                <a href="#" style="opacity: 1;">
                    <img :src="whats_logo" class="zoom-hover" alt="logo whatsapp" width="50" height="50" loading="lazy">
                </a>
            </div>
        </div>
        <div class="col-12 mb-2">
            <a style="opacity: 0.9;" target="_blank" href="https://monocilindrero.com/" class="text-decoration-none text-white mt-1 zoom-hover">
                Desarrollado por <b style="color: red">Monocilindrero</b>
            </a>
        </div>
    </footer>
</template>

<script setup>
    const wiks_logo = require('ASSETS/wiks_logo')
    const google_play = require('ASSETS/icons/googleplay')
    const appstore = require('ASSETS/icons/appstore')
    const fb_logo = require('ASSETS/icons/facebook-white')
    const ins_logo = require('ASSETS/icons/instagram-white')
    const tk_logo = require('ASSETS/icons/tiktok-white')
    const whats_logo = require('ASSETS/icons/wa-white')

    import { ref, computed, reactive, resolveComponent } from 'vue';
    import { useRouter } from 'vue-router'
    import { useStore } from 'vuex';
    

    //hooks
    const store = useStore();
    const router = useRouter();

    //variables
    const options = [
        {
            label: 'INICIO',
            route: 'post-home'
        },
        // {
        //     label: 'PROMOCIONES'
        // },
        {
            label: 'PRODUCTOS',
            route: 'brands'
        },
        {
            label: 'CARRITO',
            route: 'shopping-cart'
        },
        {
            label: 'WIKS PUNTOS',
            route: 'points-checkout'
        }
    ]
    const options2 = [
        {
            label: 'Politicas de privacidad',
            route: ''
        },
        // {
        //     label: 'PROMOCIONES'
        // },
        {
            label: 'Terminos y condiciones',
            route: ''
        },
        {
            label: 'Contacto',
            route: ''
        },
        {
            label: 'Bolsa de trabajo',
            route: ''
        }
    ]
    //computed
    const has_session  = computed(() => (store.getters['getLogin']));
</script>
<style scoped>
.zoom-hover:hover{
        transform: scale(1.12);
    } 
</style>
