export default [
    {
        path: '/products-points',
        name: 'points-products',
        meta: {
            layout: 'default'
        },
        component: () => import('VIEWS/wiksPoints/Products.vue')
    },
    {
        path: '/product-points/:id',
        name: 'product-description',
        meta: {
            layout: 'default'
        },
        component: () => import('VIEWS/wiksPoints/ProductDetail.vue')
    },
    {
        path: '/product-points/checkout',
        name: 'points-checkout',
        meta: {
            layout: 'default'
        },
        component: () => import('VIEWS/wiksPoints/PointsCheckout.vue')
    }
]