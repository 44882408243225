import { createRouter, createWebHistory } from 'vue-router'
import Home from 'ROUTER/modules/home.js'
import Categories  from 'ROUTER/modules/categories'
import ShoppingCart from 'ROUTER/modules/shoppingCart'
import Offers from 'ROUTER/modules/offers'
import Products from 'ROUTER/modules/products'
import Points from 'ROUTER/modules/points'
import PostHome from 'ROUTER/modules/mainHome'
import Download from 'ROUTER/modules/Download'
import Login from 'ROUTER/modules/login'

const routes = [
  ...Home,
  ...Categories,
  ...ShoppingCart,
  //...Offers,
  ...Products,
  ...Points,
  ...Download,
  ...Login,
  ...PostHome
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
