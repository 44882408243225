import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// import BOOTSTRAP
import 'ASSETS/scss/bootstrap/bootstrap.scss'
// import CUSTOM
import 'ASSETS/scss/custom.scss'

import 'vue3-carousel/dist/carousel.css'

//libs
import _Lodash from 'lodash'

//templates
import T_default from 'TEMPLATES/Default.vue'

//componentes
import Footer from 'COMPONENTS/Footer.vue'
import Header from 'COMPONENTS/Header.vue'
import Input from 'COMPONENTS/Input.vue'
import Slider from 'COMPONENTS/Slider.vue'
import Card from 'COMPONENTS/Card.vue'
import Modal from 'COMPONENTS/Modal.vue'
import Alert from 'COMPONENTS/Alert.vue'
import Loader from 'COMPONENTS/Loader.vue'
import Carousel from 'COMPONENTS/Carousel.vue'
import Promo from 'COMPONENTS/Promo.vue'

createApp(App)
.component('default', T_default)
.component('ws-header', Header)
.component('ws-footer', Footer)
.component('ws-input', Input)
.component('ws-slider', Slider)
.component('ws-card', Card)
.component('ws-modal', Modal)
.component('ws-alert', Alert)
.component('ws-loader', Loader)
.component('ws-carousel', Carousel)
.component('ws-promo', Promo)
.provide('$_', _Lodash)
.use(store)
.use(router)
.mount('#app')
